<template>
  <div>
    <p class="title"><el-link type="primary" :underline="false" style="fontSize:20px"
        @click="homepage">商品仓库</el-link>>><span>商品添加</span> </p>
    <ul class="s_letter_search">
      <li v-for="(item, index) in caroptions" @click="handleCommand(item.value)" :key="index">
        {{ item.lable }}
      </li>
    </ul>

    <el-dialog :visible.sync="dialogFormVisible" width="500px" center :before-close="handleCloserg">
      <span slot="title">
        <span style="font-size: 16px;" v-if="supplier_num == 166">人工代充商品添加</span>
        <span style="font-size: 16px;" v-if="supplier_num == 168">组合商品商品添加</span>
        <!-- <span style="font-size: 16px;" v-else-if="supplier_num==34" >本香商品添加</span>
              <span style="font-size: 16px;" v-else-if="supplier_num==35" >86885商品添加</span>
              <span style="font-size: 16px;" v-else-if="supplier_num==36" >喜马拉雅商品添加</span> -->
      </span>
      <div style="margin:0 auto">
        <el-form ref="fd_form" :model="fd_form" label-width="140px" :rules="fd_rules" status-icon :inline="true">
          <el-form-item label="商品名称" prop="shopname">
            <el-input v-model="fd_form.shopname"></el-input>
          </el-form-item>
          <el-form-item label="商品销售价" prop="price">
            <el-input v-model="fd_form.price"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="addgoods('fd_form')" style="marginLeft:170px">立即添加</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="addgoodsbyid_show" width="500px">
      <span slot="title">
        <span v-if="supplier_num == 2" style="font-size: 16px;">卡盟商品添加</span>
        <span v-if="supplier_num == 16" style="font-size: 16px;">商盟商品添加</span>
        <span v-if="supplier_num == 29" style="font-size: 16px;">工匠人心商品添加</span>
        <span v-if="supplier_num == 15" style="font-size: 16px;">凯捷数娱商品添加</span>
        <!-- <span v-if="supplier_num==29" style="font-size: 16px;">工匠人心商品添加</span> -->
        <!-- 昊天备份平台 -->
        <span v-if="supplier_num == 1" style="font-size: 16px;">易派商品添加</span>
        <span v-if="supplier_num == 11" style="font-size: 16px;">邀购电子商品带票添加</span>
      </span>
      <p style="text-align:center;height:60px;margin:0"><label for="code">商品编码：</label><el-input name="code"
          v-model.trim="goods_code" placeholder="请输入商品编号" style="width:160px"></el-input></p>
      <el-button type="success" @click="add_goodsbyid()">添加</el-button>
    </el-dialog>

    <el-dialog :visible.sync="cheng_yan_da_show" width="630px" :before-close="cyd_handleClose">
      <span slot="title">
        <span v-if="supplier_num == 8" style="font-size: 16px;">橙券商品添加</span>
        <span v-else-if="supplier_num == 24" style="font-size: 16px;">分销E商品添加</span>
        <span v-else-if="supplier_num == 28" style="font-size: 16px;">胜始科技商品添加</span>
        <span v-else-if="supplier_num == 31" style="font-size: 16px;">葫芦商品添加</span>
        <span v-else-if="supplier_num == 33" style="font-size: 16px;">EM迈诚商品添加</span>
      </span>
      <el-form ref="cyd_form" :model="cyd_form" :rules="cyd_rules" label-width="130px" status-icon :inline="true">
        <el-form-item label="商品编码" prop="coding">
          <el-input v-model.trim="cyd_form.coding"></el-input>
        </el-form-item>
        <el-form-item label="商品名称" prop="good_name">
          <el-input v-model.trim="cyd_form.good_name"></el-input>
        </el-form-item>
        <el-form-item label="商品进价" prop="purchase_price">
          <el-input v-model.trim="cyd_form.purchase_price"></el-input>
        </el-form-item>
        <el-form-item label="商品销售价格" prop="price">
          <el-input v-model.trim="cyd_form.price"></el-input>
        </el-form-item>
        <br />
        <el-form-item v-if="supplier_num == 31">
          <el-radio-group v-model="third_party_good_type">
            <el-radio :label="0">直冲</el-radio>
            <el-radio :label="1">卡密</el-radio>
          </el-radio-group>
        </el-form-item>
        <br />
        <el-form-item>
          <el-button type="primary" @click="cydy_add('cyd_form')">立即添加</el-button>
          <el-button type="danger" @click="cheng_yan_da_show = false, resetForm('cyd_form')">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog title="交易吧商品添加" :visible.sync="JYB_syncshow" width="630px" :before-close="handleClose">
      <el-form ref="form" :model="form" :rules="rules" label-width="140px" status-icon :inline="true">
        <el-form-item label="商品名称：" prop="good_name">
          <el-input v-model.trim="form.goods_name"></el-input>
        </el-form-item>
        <el-form-item label="商品编码：" prop="coding">
          <el-input v-model.trim="form.coding"></el-input>
        </el-form-item>
        <el-form-item label="商品进价：" prop="purchase_price">
          <el-input v-model.trim="form.purchase_price"></el-input>
        </el-form-item>
        <el-form-item label="商品销售：" prop="price">
          <el-input v-model.trim="form.price"></el-input>
        </el-form-item>
        <el-form-item label="商品类型：" prop="product_id">
          <el-select v-model="form.product_id" placeholder="请选择商品类型" style="width:200px">
            <el-option label="Q币" value="10"></el-option>
            <el-option label="会员|游戏" value="13"></el-option>
          </el-select>
        </el-form-item>

        <br>
        <el-form-item>
          <el-button type="primary" @click="JYB_sync('form')">立即添加</el-button>
          <el-button type="danger" @click="JYB_syncshow = false, resetForm('form')">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
export default {
  inject: ['chanlist'],
  data() {
    return {
      rules: {
        good_name: [
          { required: true, message: '请输入商品名称', trigger: 'blur' },
        ],
        coding: [
          { required: true, message: '请输入商品编码', trigger: 'blur' }
        ],
        product_id: [
          { required: true, message: '请选择商品类型', trigger: 'change' }
        ],
        productType: [
          { required: true, message: '请选择充值类型', trigger: 'change' }
        ],
        price: [
          { required: true, message: '请输入商品销售价', trigger: 'blur' },
        ],
        purchase_price: [
          { required: true, message: '请输入商品进价', trigger: 'blur' },
        ],

      },
      form: {
        good_name: "",
        coding: "",
        purchase_price: "",
        product_id: "",
      },
      JYB_syncshow: false,
      cheng_yan_da_show: false,
      cyd_rules: {
        good_name: [
          { required: true, message: '请输入商品名称', trigger: 'blur' }
        ],
        coding: [
          { required: true, message: '请输入商品编码', trigger: 'blur' }
        ],
        purchase_price: [
          { required: true, message: '请输入商品进价', trigger: 'blur' }
        ],
        price: [
          { required: true, message: '请输入商品销售价', trigger: 'blur' }
        ],
      },
      third_party_good_type: 0,
      cyd_form: {
        coding: "",
        good_name: "",
        purchase_price: "",
        price: ""
      },
      goods_code: "",
      addgoodsbyid_show: false,
      fd_rules: {
        shopname: [{ required: true, message: '请输入商品名称', trigger: 'blur' }],
        price: [{ required: true, message: '请输入商品销售价', trigger: 'blur' }],
      },
      fd_form: {
        shopname: "",
        price: ""
      },
      dialogFormVisible: false,
      supplier_num: "",
      caroptions: [

        // {
        //       value:"4",
        //       lable:"福禄商品添加"
        //   },
        // {
        //       value:"1",
        //       lable:"易派商品添加"
        //   },
        // {
        //       value:"2",
        //       lable:"卡盟商品添加"
        //   },
        //  {
        //      value:"3",
        //      lable:"交易吧商品添加"
        //  },
        {
          value: "166",
          lable: "人工代充商品添加"
        },
        {
          value: "8",
          lable: "橙券商品添加"
        }, {
          value: "24",
          lable: "分销e添加商品"
        },
        // {
        //     value:"16",
        //     lable:"商盟添加商品"
        // },
        {
          value: "28",
          lable: "胜始科技添加商品"
        },
        {
          value: "29",
          lable: "工匠人心商品添加"
        },
        {
          value: "15",
          lable: "凯捷数娱商品添加"
        },
        {
          value: "31",
          lable: "葫芦商品添加"
        },
        {
          value: "33",
          lable: "EM迈诚商品添加"
        },
        {
          value: "10",
          lable: "蓝色兄弟商品添加"
        },
        {
          value: "11",
          lable: "邀购电子商品带票添加"
        },

        {
          value: "29",
          lable: "工匠人心商品添加"
        },
        {
          value: "168",
          lable: "组合商品商品添加"
        },
      ]
    }
  },
  mounted() {
    this.chanlist("1-1", '1')
  },
  methods: {
    JYB_sync(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.form);
        } else {
          this.$message({
            message: '请正确填写商品信息',
            type: 'error',
            center: 'true',
          });
          return false;
        }
      });
    },
    handleClose(done) {
      done();
    },
    handleCloserg(done) {
      this.resetForm('fd_form')
      done();
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    cyd_handleClose(done) {
      this.resetForm('cyd_form')
      done();
    },
    cydy_add(formName) {
      var url;
      if (this.supplier_num == 24) {
        url = "/supplier/good/add.fenxiaoe"
      } else if (this.supplier_num == 8) {
        url = '/supplier/good/add.chengjuan'
      } else if (this.supplier_num == 28) {
        url = '/supplier/good/add.shengshikeji'
      } else if (this.supplier_num == 31) {
        url = '/supplier/good/add.haotian'
        this.cyd_form.face_value = 0
        this.cyd_form.third_party_good_type = this.third_party_good_type
      } else if (this.supplier_num == 33) {
        url = '/supplier/good/add.emmaicheng'
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios.post(url, this.cyd_form).then((res) => {
            if (res.data.code == 20000) {
              this.$message({
                type: "success",
                message: res.data.message
              })
              this.cheng_yan_da_show = false
              this.homepage()
            } else {
              this.$message({
                type: "warning",
                message: res.data.message
              })
            }
          })
        }
      })
    },
    homepage() {
      this.$router.push({ path: "/homes/merchant_order" })
    },
    addgoods(fd_form) {
      this.$refs[fd_form].validate((valid) => {
        if (valid) {
          let url = this.supplier_num == 166 ? '/supplier/good/manually.add.products' : '/supplier/good/add.combination'
          // supplier/good/add.combination
          this.$axios.post(url, { good_name: this.fd_form.shopname, price: this.fd_form.price })
            .then((res) => {
              if (res.data.code == 20000) {
                this.$message({
                  message: res.data.message,
                  type: 'success'
                });
                this.dialogFormVisible = false
                this.homepage()
              } else {
                this.$message({
                  message: res.data.message,
                  type: 'warning'
                });
              }
            })
        } else {
          return false;
        }
      });

    },
    add_goodsbyid() {
      var url;
      if (this.supplier_num == 2) {
        url = "/supplier/good/create/kashang"
      } else if (this.supplier_num == 16) {
        url = "/supplier/good/create/shangmeng"
      } else if (this.supplier_num == 29) {
        url = "/supplier/good/create/yaogou"
      } else if (this.supplier_num == 1) {
        url = "/supplier/good/add.yipai"
      } else if (this.supplier_num == 15) {
        url = "/supplier/good/add.kaijieshuyu"
      } else if (this.supplier_num == 11) {
        url = "/supplier/good/create/yaogoudaipiao"
      }
      this.$axios.post(url, { code: this.goods_code }).then((res => {
        if (res.data.code == 20000) {
          this.$message({
            type: "success",
            message: "添加成功"
          })
          this.$router.push("/homes/merchant_order")
          this.addgoodsbyid_show = false
        } else {
          this.$message({
            type: "warning",
            message: res.data.message
          })
        }
      }))
    },
    handleCommand(command) {
      this.third_party_good_type = 0
      this.fd_form.shopname = ""
      this.fd_form.price = ""
      this.goods_code = ''
      if (command == 166 || command == 168) {
        this.supplier_num = command
        this.dialogFormVisible = true
      } else if (command == 2) {
        this.addgoodsbyid_show = true;
        this.supplier_num = 2
      } else if (command == 16) {
        this.addgoodsbyid_show = true;
        this.supplier_num = 16
      } else if (command == 29) {
        this.addgoodsbyid_show = true;
        this.supplier_num = 29
      } else if (command == 15) {
        this.addgoodsbyid_show = true;
        this.supplier_num = 15
      } else if (command == 1) {
        this.addgoodsbyid_show = true;
        this.supplier_num = 1
      } else if (command == 8) {
        this.cheng_yan_da_show = true;
        this.supplier_num = 8
      } else if (command == 24) {
        this.cheng_yan_da_show = true;
        this.supplier_num = 24
      } else if (command == 28) {
        this.cheng_yan_da_show = true;
        this.supplier_num = 28
      } else if (command == 31) {
        this.cheng_yan_da_show = true;
        this.supplier_num = 31
      } else if (command == 33) {
        this.cheng_yan_da_show = true;
        this.supplier_num = 33
      } else if (command == 3) {
        this.JYB_syncshow = true;
        this.supplier_num = 3
      } else if (command == 10) {
        this.supplier_num = 10
        this.blue_bro()
      } else if (command == 11) {
        this.addgoodsbyid_show = true;
        this.supplier_num = 11

      }

    },
    blue_bro() {
      this.$axios.post("/supplier/good/add.lansexiongdi")
        .then(res => {
          if (res.data.code == 20000) {
            this.$message({
              message: res.data.message,
              type: "success"
            })
            this.$router.push("/homes/merchant_order")
          } else {
            this.$message({
              message: res.data.message,
              type: "warning"
            })
          }
        })
    },
  },
}
</script>

<style lang="less" scoped>
.title {
  font-size: 18px;
  height: 40px;
  line-height: 40px;
  color: #000;
  padding-left: 20px;
  border-bottom: 1px solid #ccc;
  text-align: left;
}

.s_letter_search {
  min-height: 34px;
  list-style: none;
  text-align: center;
  display: block;
  width: 96%;
  margin: 10px auto;
  line-height: 20px;
  padding: 1px;
  overflow: hidden;

  >li {
    float: left;
    height: 36px;
    display: block;
    min-width: 200px;
    cursor: pointer;
    line-height: 36px;
    font-size: 15px;
    font-family: "Microsoft Yahei", "Hiragino Sans GB", Arial, sans-serif;
    font-weight: 400;
    margin: 0 10px;
  }
}
</style>